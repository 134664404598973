import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from './Components/Newheader/header';
import Hero from './Components/Home/hero/Hero';
import About from './Components/About/about';
import Services from './Components/Services/services';
import Footer from './Components/Footer/Footer';
import Router from '../src/Router'
import { Helmet } from 'react-helmet';



function App() {
  global.proname = "mbn"
  global.website = "https://www.mbnconsultancy.co.uk/"
  return (
    <div>
    <div>
    <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
    </div>      
    <Header/>
    <Router />
    <Footer />
  </div>
  );
}

export default App;
