import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";


function Footer(){
    return(
        <div>
            <br></br>
             <footer class="main-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                    <div class="about-widget footer-widget">
                        <div class="footer-title" ><h4 style={{color:'003366'}}>ABOUT US</h4></div>
                        <div class="text">
                        At MBN Investment Consultancy, we understand that making sound 
investment decisions requires a deep understanding of the markets,
diligent analysis, and a tailored approach.
                        </div>
                        {/* <div class="contact-info">
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-phone"></i></div>
                                <div class="text"><span>Phone:</span> +44  20 3633 5190</div>
                            </div>
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-map-marker"></i></div>
                                <div class="text"><span>Address:</span>13 Hanover Square, Mayfair, London,
WS1 1HN, UK</div>
                            </div>
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-envelope"></i></div>
                                <div class="text"><span>Email:</span>info@mbnconsultancy.co.uk</div>
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div class="col-lg-3 col-md-6 col-sm-12 footer-column">
                    <div class="footer-post footer-widget">
                        <div class="footer-title"><h4>RECENT NEWS</h4></div>
                        <div class="single-post">
                            <div class="time">Learn More</div>
                            <div class="text"><a href="/blog">Traits of a Scammy Real Estate Investment Guru.</a></div>
                        </div>
                        <div class="single-post">
                            <div class="time">Learn More</div>
                            <div class="text"><a href="/blog">5 Business Books That Changed My Real Estate Investing Life.</a></div>
                        </div>
                        <div class="single-post">
                            <div class="time">Learn More</div>
                            <div class="text"><a href="/blog">These Are  Best Ways to Finance Multifamily Investments.</a></div>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg-4 col-md-6 col-sm-12 footer-widget">
                    <div class="link-widget footer-widget">
                        <div class="footer-title"><h4>USEFULL LINKS</h4></div>
                        <ol  class="list">
                            <li><a href="/"> Home</a></li>
                         
                            <li><a href="/mainabout">About </a></li>
                            <li><a href="/mainservices">Services</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/contact">Contact</a></li>
                            
                        </ol>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-12 footer-column">
                    <div class="about-widget footer-widget">
                        <div class="footer-title"><h4>GET IN TOUCH</h4></div>
                        <div class="text">
                       
                        </div>
                        <div class="contact-info">
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-phone"></i></div>
                                <div class="text"><span>Phone:</span> +44  20 3633 5190</div>
                            </div>
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-map-marker"></i></div>
                                <div class="text"><span>Address:</span>13 Hanover Square, Mayfair, London,
WS1 1HN, UK</div>
                            </div>
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-envelope"></i></div>
                                <div class="text"><span>Email:</span>info@mbnconsultancy.co.uk</div>
                            </div>
                            <div class="single-item">
                                <div class="icon-box"><i class="fa fa-envelope"></i></div><br></br>
                                <a  href="https://www.instagram.com/_mbnconsultancy/?igshid=MzRlODBiNWFlZA%3D%3D"><i class="fa fa-twitter" >< FaInstagram ></FaInstagram></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="mailto:info@mbnconsultancy.co.uk"><i class="fa fa-pinterest-p"><FaMailBulk></FaMailBulk></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
                            {/* <li><a href="#"><i class="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li> */}
                            <li></li>
                            {/* <li><a href="#"><i class="fa fa-google-plus"><FaTwitter></FaTwitter></i></a></li> */}
                            <li></li>
                           
                       
        </div>
    </footer>
  
    <div class="footer-bottom centred">
        <div class="container">
            <div class="copyright"><a target="_blank" >copyright@mbn</a></div>
        </div>
    </div>
   

        </div>
    )
}
export default Footer