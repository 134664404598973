import React from "react";
import { FaBeer } from 'react-icons/fa';
import {FaLocationArrow} from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaMailBulk } from "react-icons/fa";
import { FaInstagram ,FaTwitter,FaFacebook} from "react-icons/fa";
import img1 from '../../Assets/Images/corporate-management-strategy-solution-branding-concept_1500x424.jpg'
import img from '../../Assets/Images/side-view-men-with-safety-vests-shaking-hands.jpg'
function Contact(){
    return(
        <div>
            <section className="page-title centred" style={{backgroundImage: `url(${img1 })`}}>
        <div className="container">
            <div className="content-box"><h2>Contact</h2></div>
        </div>
    </section>
    
    <section className="google-map-section map-style-two">
        <div className="google-map-area">
            <div >
           
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9293086380903!2d-0.14371409999999998!3d51.5145129!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052ad6f571d3%3A0x56d81e3bd0ccd36f!2sLilly%20House%2C%2013%20Hanover%20Square%2C%20London%20W1S%201HN%2C%20UK!5e0!3m2!1sen!2sin!4v1692962589260!5m2!1sen!2sin"  width="1350" height="500"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 

            </div>
        </div>
    </section>
   
   
   
    <section className="contact-section sec-pad">
        <div className="container" style={{textAlign:'center',alignItems:'center'}}>
      
       
            <div className="row">
                {/* <div className="col-lg-3 col-md-12 col-sm-12 contact-column" style={{backgroundColor:'lightblue',borderRadius:5}}>
                    <div className="contact-info">
                        <div className="title"><h4 style={{color:'black'}}><b>Address</b></h4></div>
                        <div className="single-item">
                          
                            <div className="text"> <i ><FaLocationArrow style={{color:'#003366'}}  ></FaLocationArrow></i>&nbsp;&nbsp;13 Hanover Square, Mayfair, London,
WS1 1HN, UK</div>
                        </div>
                        
                      </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 contact-column"  style={{backgroundColor:'lightblue',borderRadius:5,marginLeft:35}}>
            <div className="title"><h4 style={{color:'black'}}><b>Phone</b></h4></div><br></br>
                        <div className="single-item">
                           
                            <div className="text"> <i ><FaPhone style={{color:'#003366'}} ></FaPhone></i>&nbsp;&nbsp;+44  20 3633 5190</div>
                        </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 contact-column"  style={{backgroundColor:'lightblue',borderRadius:5,marginLeft:35}}>
            <div className="title"><h4 style={{color:'black'}}><b>Mail</b></h4></div><br></br>
                        <div className="single-item">
                           <i ><a href="mailto:info@mbnconsultancy.co.uk"></a></i>
                            <div className="text"><i><FaMailBulk style={{color:'#003366'}}></FaMailBulk></i>&nbsp;&nbsp;info@mbnconsultancy.co.uk</div>
                        </div>
            </div> */}
          

                
                <div  className="service-block col-lg-4 col-md-6 col-sm-12"  >
                <div className="contact-info">
                        <div className="title"><h4 style={{color:'black'}}><b>Address</b></h4></div>
                        <div className="single-item">
                          
                            <div className="text"> <i ><FaLocationArrow style={{color:'#003366'}}  ></FaLocationArrow></i>&nbsp;&nbsp;13 Hanover Square, Mayfair, London,
WS1 1HN, UK</div>
                        </div>
                        
                      </div>
                </div>
                <div  className="service-block col-lg-4 col-md-6 col-sm-12" >
                <div className="title"><h4 style={{color:'black'}}><b>Phone</b></h4></div>
                        <div className="single-item">
                           
                            <div className="text"> <i ><FaPhone style={{color:'#003366'}} ></FaPhone></i>&nbsp;&nbsp;+44  20 3633 5190</div>
                        </div>
                </div>
                <div  className="service-block col-lg-4 col-md-6 col-sm-12">
                <div className="title"><h4 style={{color:'black'}}><b>Mail</b></h4></div>
                        <div className="single-item">
                           <i ><a href="mailto:info@mbnconsultancy.co.uk"></a></i>
                            <div className="text"><i><FaMailBulk style={{color:'#003366'}}></FaMailBulk></i>&nbsp;&nbsp;info@mbnconsultancy.co.uk</div>
                        </div>
                </div>
            </div>
        </div>
        <div className="social-area centred">
        <div className="container">
            <ul className="social-list">
                {/* <li><a href="#"><i className="fa fa-facebook"><FaFacebook></FaFacebook></i></a></li> */}
                <li><a href="https://www.instagram.com/_mbnconsultancy/?igshid=MzRlODBiNWFlZA%3D%3D"><i className="fa fa-dribbble"><FaInstagram></FaInstagram></i></a></li>
                {/* <li><a href="#"><i className="fa fa-twitter"><FaTwitter></FaTwitter></i></a></li> */}
                {/* <li><a href="#"><i className="fa fa-vimeo"></i></a></li> */}
                <li><a href="mailto:info@mbnconsultancy.co.uk"><i class="fa fa-pinterest-p"><FaMailBulk></FaMailBulk></i></a></li>
            </ul>
        </div>
    </div>
   
    </section>
        </div>
    )
}
export default Contact