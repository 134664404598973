import React from "react";
import img from '../../Assets/Images/businessmen-elegant-suits-business-meeting-discussing-new-project-office.jpg'
import './services.css'
import img1 from '../../Assets/Images/businessmen-hands-white-table-with-documents-drafts.jpg'
import { Fade } from "react-bootstrap";
import Partners from "../About/partner";
import { FaCashRegister, FaInstagram } from "react-icons/fa";
import { FaHome,FaDollarSign,FaRupeeSign,FaMoneyCheck ,FaMoneyBill,FaBuilding} from "react-icons/fa";
import gif1 from '../../Assets/gif/icons8-cash.gif'
import gif2 from '../../Assets/gif/icons8-debit-card.gif'
import gif3 from '../../Assets/gif/icons8-money.gif'
import gif4 from '../../Assets/gif/icons8-payment-processed.gif'
import gif5 from '../../Assets/gif/icons8-time.gif'
import gif6 from '../../Assets/gif/icons8-company.gif'
function Services(){
    return (
        <div>
             {/* <div style={{
      padding: '7%',
      backgroundImage: `url(${img })`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover', 
      marginTop: 15,
      height: '10%' 
     
    }}>

<h2 style={{textAlign:'center',fontWeight:900,color:'whitesmoke',fontFamily:'sans-serif'}}></h2>

    </div> */}
    
     <section className="testimonial-section sec-pad centred" style={{backgroundImage: `url(${img })`}}>
        <div className="container">
            <h2 className="sec-title">  Guiding Your Financial Journey: Where Expertise Meets Strategy.</h2>
            <div className="title-text" style={{fontWeight:200}}>MBN ’s advisory service gives investors access to expert 
support as they manage their investment portfolio in the 
UK in a flexible, efficient way...</div>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 offset-lg-2">
                    
                </div>
            </div>
        </div>
    </section>
 
 
    <section class="services-section">
        <div class="auto-container">
            <div class="sec-title">
                <span class="title">we offer the best consultancy</span>
                <h2> Services</h2>
            </div>

            <div class="row">
               
                <div class="service-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box"><span><img src={gif1}></img></span></div>
                        <h4><a href="/mainservices">ASSET MANAGEMENT
INVESTMENT CONSULTANCY</a></h4>
                        <div class="text">  Customized strategies to ensure optimal adequacy 
and profiling.
In addition to financial consulting, wealth 
management is our company’s core business.</div>
                        <div class="link-box"><a href="/mainservices">Read More</a></div>
                    </div>
                </div>

               
                <div class="service-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box"><span><img src={gif4} style={{width:70}}></img></span></div>
                        <h4><a href="/mainservices">PRIVATE EQUITY AND
ALTERNATIVE FINANCE</a></h4>
                        <div class="text">  For our clients we design and create tailor-made solutions for investing 
in the private equity and venture capital market<br></br><br></br></div>
                        <div class="link-box"><a href="/mainservices">Read More</a></div>
                    </div>
                </div>

                
                <div class="service-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box"><span><img src={gif2} style={{width:90}}></img></span></div>
                        <h4><a href="/mainservices">ASSET PLANNING
AND OPTIMIZATION</a></h4>
                        <div class="text">  We study solutions for heritage planning together 
with our customers, supporting them in extraordinary 
corporate operations <br></br><br></br></div>
                        <div class="link-box"><a href="/mainservices">Read More</a></div>
                    </div>
                </div>

                
                <div class="service-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box"><img src={gif6} style={{width:70}} ></img></div>
                        <h4><a href="/mainservices">MANAGEMENT OF
REAL ESTATE ASSETS</a></h4>
                        <div class="text">Because real estate often amounts to little used 
wealth, we help our customers to make the assets more 
liquid, through collateral operations.<br></br></div>
                        <div class="link-box"><a href="/mainservices">Read More</a></div><br></br>
                    </div>
                </div>

                
                <div class="service-block col-lg-4 col-md-6 col-sm-12">
                    <div class="inner-box">
                        <div class="icon-box"><span><img src={gif5} style={{width:90}}></img></span></div>
                        <h4><a href="/mainservices">Risk Management</a></h4>
                        <div class="text"> Customized strategies to ensure optimal adequacy 
and profiling.
In addition to financial consulting, wealth 
management is our company’s core business.</div>
                        <div class="link-box"><a href="/mainservices">Read More</a></div><br></br>
                    </div>
                </div>

              
               
            </div>
        </div>
    </section>
  
    
     <section className="testimonial-section sec-pad centred" style={{backgroundImage: `url(${img1 })`}}>
        <div className="container">
            <h2 className="sec-title">  Turning financial complexity into strategic simplicity.</h2>
            <div className="title-text" style={{fontWeight:200}}>MBN ’s advisory service gives investors access to expert 
support as they manage their investment portfolio in the 
UK in a flexible, efficient way...</div>
            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 offset-lg-2">
                    
                </div>
            </div>
        </div>
    </section>
    
    
  
        </div>
        
    )
}
export default Services